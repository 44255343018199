import { useCallback } from "react";
import styled, { css } from "styled-components";

const StyledInput = styled.input`
	padding: 12px;
	background-color: #f5f5f5;
	border-radius: 3px;
	border: none;
	outline: none;
	font-family: inherit;
	font-size: inherit;
	flex: 1;

	${ props => props.disabled && css`
			background-color: #fff;
			color: black;
		`}
`

const Input = ({tag="p", style, value, onChange, onSubmit, type, placeholder, disabled}) => {

	const handleChange = useCallback(evt => onChange(evt.target.value), [onChange])

	const handleKeyDown = useCallback(evt => {
		if(onSubmit && evt.key === 'Enter'){
			onSubmit();
		}
	}, [ onSubmit ])

	const T = tag;

	return <T style={{display: "flex"}}>
		<StyledInput
			disabled={disabled}
			placeholder={placeholder}
			type={type}
			style={style}
			value={value}
			onChange={handleChange}
			onKeyDown={handleKeyDown}
		/>
	</T>

}

export default Input;