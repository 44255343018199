import styled from "styled-components";
import { decodeError } from "./../config/errors";

const P = styled.p`
	color: red;
`

const Error = ({error}) => {

	return error ? <P>{decodeError(error)}</P> : null

}

export default Error;