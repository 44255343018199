import Callbacks from "./../utils/Callbacks";
import { useState, useEffect } from "react";
import { Button, Modal } from "./../components";

const callbacks = new Callbacks();

let resolve;

const confirm = config => {
	const ret = new Promise(res => resolve = res);
	callbacks.dispatch('open', config);
	return ret;
}

const closeConfirm = () => callbacks.dispatch('close');

const useConfirm = () => {

	return { confirm, closeConfirm };
}

export const Confirmer = () => {

	const [ config, setConfig ] = useState(null);

	useEffect(() => {
		return callbacks.on({
			open: config => setConfig(Object.assign({
				title: "Are you sure?",
				description: null,
				confirm: "Yes",
				cancel: "Cancel",
				allowBackgroundClose: true
			}, config)),
			close: () => setConfig(null)
		});
	}, []);

	const handleChoice = confirmed => {
		setConfig(null);
		if(resolve){
			resolve(confirmed);	
		}
	}

	return config ? <Modal open onClose={config.allowBackgroundClose ? () => handleChoice(false) : null}>
		<h3>{config.title}</h3>
		<p>{config.description}</p>
		<div>
			{
				config.confirm ? <Button style={{
					marginRight: "12px",
				}} label={config.confirm} onClick={() => handleChoice(true)} /> : null
			}
			{
				config.cancel ? <Button label={config.cancel} onClick={() => handleChoice(false)} /> : null
			}
		</div>
	</Modal> : null

}


export default useConfirm;