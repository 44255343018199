import { query, getFirestore, collection, onSnapshot, where } from "firebase/firestore";
import { useState, useEffect } from "react";
import useAuth from "./../hooks/useAuth";
import { useHistory, Link }  from "react-router-dom";
import { Button, Loading } from './../components';

const Home = () => {

	const { user } = useAuth();
	const [ recipes, setRecipes ] = useState([])
	const [ loading, setLoading ] = useState(true);
	const [ error, setError ] = useState(null);

	const sortedRecipes = recipes.sort((r1, r2) => r1.name > r2.name ? 1 : -1);

	const history = useHistory();

	useEffect(() => {
		if(user?.uid){
			const db = getFirestore();
			const q = query(collection(db, "recipes"), where("owner", "==", user.uid));
			const unsub = onSnapshot(q, snapshot => {
				const r = [];
				snapshot.forEach(doc => {
					const data = doc.data();
					data.id = doc.id;
					r.push(data);

				})
				setRecipes(r);
				setLoading(false);
				setError(null);
			}, error => {
				setError(error);
			})
			return unsub;
		}
	}, [user])

	return <>
		<h2>Your Recipes</h2>
		{
			error ? <p>Error: {error.message}</p> : null
		}
		{
			loading ? <Loading label="" /> : null
		}
		{
			!loading && recipes.length === 0 ? <>
				<p>You have no recipes</p>
				<Button label="Create recipe" onClick={() => history.push("/new")} />
			</> : null
		}
		{
			sortedRecipes.map((r, i) => <Link style={{
				display: "block",
				margin: "12px 0px",
				width: "fit-content",
			}} to={`/recipe/${r.id}`} key={i}>{r.name}</Link>)
		}
	</>

}

export default Home;