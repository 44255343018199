import { Button } from "./../../components";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { getFunctions, httpsCallable } from "firebase/functions";
import { v4 as uuid } from 'uuid';

const getBlob = url => new Promise(function(resolve, reject) {
	try {
		const xhr = new XMLHttpRequest();
		xhr.open("GET", url);
		xhr.responseType = "blob";
		xhr.onerror = () => { reject("Network error.") };
		xhr.onload = () => {
			if (xhr.status === 200) {
				resolve(xhr.response)
			}
			else {
				reject("Loading error:" + xhr.statusText)
			}
		};
		xhr.send();
	}
	catch(err) {
		reject(err.message)
	}
});


const TestVisionDirect = () => {

	return <Button label="TestUpload" onClick={async () => {
		// const base64 = await toDataURL('https://tesseract.projectnaptha.com/img/eng_bw.png');
		const blob = await getBlob('https://tesseract.projectnaptha.com/img/eng_bw.png');
		// const buffer = await blob.arrayBuffer();
		// const bytes = new Uint8Array(buffer);
		// const buffer = await toBuffer('https://tesseract.projectnaptha.com/img/eng_bw.png');
		// const base64Blob = await blobToBase64(blob);
		// console.log({bytes});
		console.log("Sending file...");

		const imageLocation = `temp/${uuid()}`;
		const result = await uploadBytes(ref(getStorage(), imageLocation), blob).then((snapshot) => {
			console.log('Uploaded a blob or file!');
		});
		console.log("storage result", {result});
		const downloadUrl = await getDownloadURL(ref(getStorage(), imageLocation));
		console.log({downloadUrl});
		const url = downloadUrl;
		console.log("Firing analysis request...", url);
		const analysisResult = await httpsCallable(getFunctions(), 'textDetect')({ url });
		console.log({analysisResult});
	}} />

}

export default TestVisionDirect;