import './App.css';
import { Switch, Route } from "react-router-dom";
import useAuth from "./hooks/useAuth";
import Header from "./pages/Header";
import SignIn from "./pages/SignIn";
import Home from './pages/Home';
import New from "./pages/New";
import About from "./pages/About";
import Recipe from "./pages/Recipe";
import Edit from "./pages/Edit";
import Test from "./pages/Test";
import { Loading } from "./components";
import { Confirmer } from "./hooks/useConfirm";
import { Portals } from "./hooks/usePortals";
import { Scanner } from "./hooks/useScanner";

function App() {

	const { signingInAnon, signingInInitial } = useAuth();

	if(signingInAnon || signingInInitial){
		return <Loading />
	}

	return <div style={{
		padding: "0px 12px 48px 12px",
	}}>
		<Header />
		<Switch>
			<Route path="/sign-:method">
				<SignIn />
			</Route>
			<Route path="/new">
				<New />
			</Route>
			<Route path="/about">
				<About />
			</Route>
			<Route path="/test">
				<Test />
			</Route>
			<Route path="/recipe/:recipeId/edit">
				<Edit />
			</Route>
			<Route path="/recipe/:recipeId">
				<Recipe />
			</Route>
			<Route path="/" exact>
				<Home />
			</Route>
		</Switch>
		<Confirmer />
		<Scanner />
		<Portals />
	</div>
}

export default App;
