import { TextArea, Button } from "./../components";
import useScanner from "./../hooks/useScanner";

const TextAreaScannable = props => {

	const setScannerConfig = useScanner();

	return <>
		<TextArea {...props}>
			{
				props.disabled ? null : <Button
					label={`Scan Image`}
					style={{
						position: "absolute",
						margin: "0px",
						top: "0px",
						right: "0px",
					}}
					onClick={() => {
						setScannerConfig({
							onComplete: results => {
								props.onChange(props.value + results);
								setScannerConfig(null);
							}
						})
					}}
				/>
			}
		</TextArea>
	</>

}

export default TextAreaScannable;