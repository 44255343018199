

const About = () => {

	return <>
		<h2>About</h2>
		<p>Food websites are full of chaff.</p>
		<p>When you find something worth keeping, bung it on here.</p>
		<br />
		<br />
		<p>Free forever.</p>
	</>

}

export default About;