import styled from "styled-components"
import { useCallback, useState } from "react";
import ContentEditable from "react-contenteditable"
import isMobile from 'is-mobile';

const TextAreaContainer = styled.div`
	position: relative;

	div {
		margin-top: ${props => props.spacing || 0}px;
	}
`

const Placeholder = styled.p`
	position: absolute;
	top: 0px;
	left: 0px;
	color: gray;
	pointer-events: none;
	margin: 12px;
	font-style: italic;

`

const TextArea = ({value, onChange, disabled, placeholder, style, spacing=12, children}) => {

	const [ focused, setFocused ] = useState(false);

	const handleChange = useCallback(evt => {
		onChange(evt.target.value)
	}, [onChange])

	return <TextAreaContainer spacing={spacing}>
		<ContentEditable
				html={value}
				style={Object.assign({
					padding: "12px",
					backgroundColor: disabled ? "#fff" : "#f5f5f5",
					borderRadius: "3px",
				}, style)}
				onChange={handleChange}
				disabled={disabled}
				onFocus={evt => {
					setFocused(true)
				}}
				onBlur={evt => {
					setFocused(false)
				}}
				tagName="p"
			/>
		{
			value.length === 0 && !focused ? <Placeholder>{placeholder || (isMobile ? "Tap to edit" : "Click to edit")}</Placeholder> : null
		}
		{children}
	</TextAreaContainer>
}

export default TextArea;