import React from "react";
import ReactDOM from "react-dom";
import useSharedState, { setSharedState } from "./../hooks/useSharedState";

export class Portals extends React.Component {

	componentDidMount(){
		setSharedState("portals", this);
	}

	inject = externalElement => <>
		{
			this.element && ReactDOM.createPortal(externalElement, this.element)
		}
	</>

	render(){
		return <div ref={ref => this.element = ref} style={{
			position: "absolute",
			top: "0px",
			left: "0px",
			bottom: "0px",
			right: "0px",
			pointerEvents: "none",
		}}>
		{
			// ... children get injected into here
		}
		</div>
	}

}


const usePortals = () => {
	const [ portalInstance ] = useSharedState("portals");
	return portalInstance
}

export default usePortals;