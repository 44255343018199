import { useState, useEffect } from "react";
import useAuth from "./../hooks/useAuth";
import { Loading, Error, RecipeForm, Button } from "./../components";
import { getRecipeValidationErrors } from "./../config/validation";
import { doc, setDoc, getFirestore, getDoc } from "firebase/firestore";
import { Link, useParams, useHistory } from "react-router-dom";

const Edit = () => {

	const history = useHistory();
	const { recipeId } = useParams();
	
	const [ loadError, setLoadError ] = useState(null);
	const { user } = useAuth();
	const [ recipe, setRecipe ] = useState({});
	const [ loadingRecipe, setLoadingRecipe ] = useState(true);

	useEffect(() => {
		if(recipeId){
			setLoadingRecipe(true);
			getDoc(doc(getFirestore(), "recipes", recipeId))
				.then(doc => setRecipe(doc.data()))
				.catch(error => setLoadError(error))
				.finally(() => setLoadingRecipe(false))
		}
	}, [ recipeId ])

	const [ recipeErrors, setRecipeErrors ] = useState([]);
	const [ loading, setLoading ] = useState(false);
	const [ error, setError ] = useState(null);
	const [ success, setSuccess ] = useState(false);

	const handleSubmit = async () => {
		const errors = getRecipeValidationErrors(recipe);

		setRecipeErrors(errors);
		setSuccess(false);

		if(errors.length === 0){
			setError(null);
			setLoading(true);
			try {
				recipe.owner = user.uid;
				await setDoc(doc(getFirestore(), "recipes", recipeId), recipe);
				setSuccess(true);
			} catch (error) {
				setError(error);
			} finally {
				setLoading(false)
			}
		}
	}

	return <>
		<Button label="← Back" onClick={() => history.goBack()} />
		<h2>Edit Recipe</h2>
		{
			loadingRecipe ? <Loading /> : <>
			{
				loadError ? <Error error={loadError} /> : <>
					<RecipeForm value={recipe} errors={recipeErrors} onChange={setRecipe} />
					<Button label="Save" onClick={handleSubmit} />
					<Error error={error} />
					<Loading label="Updating recipe" loading={loading} />
					{
						success ? <>
							<p>Recipe updated successfully.</p>
							<Link to={`/recipe/${recipeId}`}>View Recipe</Link>
						</> : null
					}
				</>
			}
			</>
		}
	</>

}

export default Edit;