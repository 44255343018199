import { useState, useEffect } from "react";

const Loading = ({style, label="Loading", loading=true}) => {

	const [ dots, setDots ] = useState(3);

	useEffect(() => {
		if(loading){
			const timeout = setTimeout(() => {
				setDots(dots === 3 ? 0 : dots + 1)
			}, 1000);
			return () => window.clearTimeout(timeout);
		}
	}, [ dots, loading ])

	if(!loading){
		return null;
	}

	let text = label;
	for(let i = 0; i<dots; i++){
		text += ".";
	}
	return <p style={style}><i>{text}</i></p>

}

export default Loading;