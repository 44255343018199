import "./../config/firebase";
import useSharedState, { setSharedState } from "./useSharedState";
import {
	getAuth,
	createUserWithEmailAndPassword,
	signInAnonymously,
	signInWithEmailAndPassword,
	signOut as _signOut,
	EmailAuthProvider,
	linkWithCredential
} from "firebase/auth";

setSharedState("signingIn", true);
setSharedState("signingInInitial", true);

getAuth().onAuthStateChanged(user => {
	if(user){
		console.log("Signed in", user);
		setSharedState("signedIn", true)
		setSharedState("signingIn", false)
		setSharedState("anonymous", user.isAnonymous);
		setSharedState("signingInInitial", false);
	} else {
		console.log("Signed out");
		setSharedState("signingInAnon", true)
		// setSharedState("signingIn", true)
		setSharedState("signedIn", false)
		signInAnonymously(getAuth()).then(() => {
			setSharedState("signingIn", false)
			setSharedState("signingInAnon", false)
		})
	}
})

export const signIn = async (email, password) => {
	setSharedState("signingIn", true)
	await signInWithEmailAndPassword(getAuth(), email, password)
}

export const signOut = async () => {
	await _signOut(getAuth());
}

export const signUp = async (email, password) => {
	let authFunction;
	const { currentUser } = getAuth();
	if(currentUser && currentUser.isAnonymous){
		console.log("Upgrading anonymous user...");
		authFunction = () => {
			const credential = EmailAuthProvider.credential(email, password);
			return linkWithCredential(getAuth().currentUser, credential);
		}
	} else {
		console.log("Creating new user...");
		authFunction = () => {
			return createUserWithEmailAndPassword(getAuth(), email, password);
		}
	}
	await authFunction();
	setSharedState("anonymous", false);
}

const useAuth = () => {
	const [ signedIn ] = useSharedState("signedIn");
	const [ signingIn ] = useSharedState("signingIn");
	const [ signingInAnon ] = useSharedState("signingInAnon");
	const [ anonymous ] = useSharedState("anonymous");
	const [ signingInInitial ] = useSharedState("signingInInitial");
	const user = getAuth().currentUser

	return { signedIn, signingIn, anonymous, signingInAnon, user, signingInInitial };
}

export default useAuth;