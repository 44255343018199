import { Loading, Modal, Button, TextArea } from "./../components";
import { useState } from "react";
import useSharedState, { setSharedState } from './../hooks/useSharedState';
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { getFunctions, httpsCallable } from "firebase/functions";
import { v4 as uuid } from 'uuid';

let processIid = 0;

export const Scanner = () => {

	const [ file, setFile ] = useState(null);
	const [ fileUrl, setFileUrl ] = useState(null);
	const [ loading, setLoading ] = useState(false);
	const [ results, setResults ] = useState(null);

	const [ config, setConfig ] = useSharedState("scannerConfig");

	const open = !!config;

	const handleUpload = async file => {

		processIid++;
		const pid = processIid;

		if(file){
			console.log("Loading begins...");
			setLoading(true);
			const imageLocation = `temp/${uuid()}`;
			const result = await uploadBytes(ref(getStorage(), imageLocation), file).then((snapshot) => {
				console.log('Uploaded a blob or file!');
			});
			if(pid !== processIid) { return }
			console.log("storage result", {result});
			const downloadUrl = await getDownloadURL(ref(getStorage(), imageLocation));
			if(pid !== processIid) { return }
			console.log({downloadUrl});
			const url = downloadUrl;
			console.log("Firing analysis request...", url);
			const analysisResult = await httpsCallable(getFunctions(), 'textDetect')({ url });
			if(pid !== processIid) { return }

			console.log({analysisResult});
			const lines = `<div>${analysisResult.data[0].fullTextAnnotation.text.split("\n").join("</div><div>")}</div>`;
			
			setLoading(false);
			setResults(lines)
		}
	}

	return open ? <Modal
		style={{
			display: "flex",
			flexDirection: "column",
		}}
	>
		<div style={{
			display: "flex",
		}}>
			<h3 style={{
				flex: 1,
			}}>Scan text from an image</h3>
			<Button label="X" onClick={() => setConfig(null)} />
		</div>
		<div style={{
			display: "flex",
		}}>
			<div
				style={{
					position: "relative",
				}}
			>
				<Button
					label="Choose Image"
				/>
				<input
					style={{
						position: "absolute",
						top: "0px",
						left: "0px",
						width: "100%",
						height: "100%",
						backgroundColor: "green",
						opacity: "0",
					}}
					type="file"
					accept="image/*"
					onChange={evt => {
						const file = evt.target.files.length > 0 && evt.target.files[0];
						setFile(file)
						setFileUrl(file ? URL.createObjectURL(file) : null);
					}}
					alt="Choose image to scan"
				/>
			</div>
			{
				file ? <Button
					style={{
						backgroundColor: "green",
					}}
					label="Upload"
					onClick={() => handleUpload(file)}
				/> : null
			}
		</div>
		{	
			fileUrl ? <img alt="Text Scanner Preview" style={{
				width: "100%",
				flex: 1,
				backgroundColor: 'yellow',
				objectFit: "contain",
			}} src={fileUrl} /> : null
		}
		{
			loading ? <div style={{
				position: "absolute",
				top: "0px",
				left: "0px",
				right: "0px",
				bottom: "0px",
				padding: "48px",
				backgroundColor: "white"
			}}>
				<Loading label="Scanning" />
				<Button label="Cancel" onClick={() => {
					console.log("Cancelling...");
					processIid++;
					setLoading(false);
				}} />
			</div> : null
		}
		{
			results ? <div style={{
				position: "absolute",
				top: "0px",
				left: "0px",
				right: "0px",
				bottom: "0px",
				display: "flex",
				flexDirection: "column",
				padding: "48px",
				backgroundColor: "white"
			}}>
				<h5>How's this?</h5>
				<TextArea
					style={{
						flex: 1,
						overflow: "auto",
					}}
					disabled
					value={results}
				/>
				<div>
					<Button label="That'll do" onClick={() => config?.onComplete(results)} />
					<Button label="Go back" onClick={() => {
						setResults(null);
					}} />
				</div>
			</div> : null
		}
	</Modal> : null
}

const useScanner = () => {
	return config => setSharedState("scannerConfig", config)
}

export default useScanner;