import { useState, useEffect, useCallback } from "react";
import Callbacks from "./../utils/Callbacks";

const callbacks = new Callbacks();

const sharedStateValues = {};

export const setSharedState = (key, value) => {
	sharedStateValues[key] = value;
	callbacks.dispatch(key);
}

const useSharedState = (key) => {

	const [, rerender] = useState()

	const setValue = useCallback(value => {
		sharedStateValues[key] = value;
		callbacks.dispatch(key);
	}, [ key ])

	useEffect(() => {
		const unsub = callbacks.on(key, () => rerender({}));
		return unsub;
	}, [ key ])
	
	return [ sharedStateValues[key], setValue ];

}

export default useSharedState;