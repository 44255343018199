import useAuth from "./../hooks/useAuth";
import { useParams, useHistory } from "react-router-dom";
import useConfirm from "./../hooks/useConfirm";
import { useEffect, useState } from "react";
import { getFirestore, onSnapshot, doc, deleteDoc } from "firebase/firestore";
import { WakeLockButton, Button, Loading, Error, RecipeForm } from "./../components";

const Recipe = () => {

	const { user } = useAuth();
	const { recipeId } = useParams();
	const [ recipe, setRecipe ] = useState(null)
	const [ loading, setLoading ] = useState(true)
	const [ error, setError ] = useState(null)
	const history = useHistory();
	const { confirm } = useConfirm();

	

	useEffect(() => {
		if(recipeId){
			const db = getFirestore();
			const unsub = onSnapshot(doc(db, "recipes", recipeId), doc => {
				const data = doc.data();
				if(data){
					data.id = doc.id;	
				}
				setRecipe(data);
				setLoading(false)
				setError(null)
			}, error => {
				setError(error);
				setLoading(false)
			})
			return unsub;
		}
	}, [ recipeId ])

	return <>
		<div style={{
			display: "flex",
			maxWidth: "500px" // This is kept in sync with RecipeForm
		}}>
			<Button label="← Back" onClick={() => history.goBack()} />
			<div style={{flex: 1}} />
			<WakeLockButton />
		</div>
		<Loading label="" loading={loading} />
		<Error error={error} />
		{
			recipe ? <RecipeForm
				value={recipe}
			/> : null
		}
		{
			recipe?.owner === user.uid ? <>
				<Button label="Edit" onClick={() => history.push(`/recipe/${recipeId}/edit/`)} />
				<Button label="Delete" onClick={async () => {
					const confirmed = await confirm({
						title: `Delete ${recipe.name}?`,
						description: "This cannot be undone.",
						confirm: "Delete"
					});
					console.log({confirmed});
					if(confirmed){
						await deleteDoc(doc(getFirestore(), "recipes", recipeId));
						history.push("/")
					}
				}} />
			</> : null
		}
	</>

}

export default Recipe;