import { Input, TextAreaScannable } from "./";
import styled, { css } from "styled-components";

const RecipeFormContainer = styled.div`
	border: 1px solid gray;
	padding: 0px;
	max-width: 500px;
	margin: 0px 0px 12px 0px;

	${ props => !props.disabled && css`
		padding: 12px 18px;
	` }
`

const RecipeForm = ({value, onChange, errors}) => {

	return <RecipeFormContainer disabled={!onChange}>
		<Input
			disabled={!onChange}
			placeholder="Recipe title"
			tag="h3"
			value={value.name}
			style={errors && errors.includes("name") ? { border: "1px solid red"} : null}
			onChange={name => onChange(Object.assign({}, value, {name}))}
		/>
		<TextAreaScannable
			disabled={!onChange}
			spacing={3}
			placeholder="Ingredients"
			value={value.ingredients}
			style={errors && errors.includes("ingredients") ? { border: "1px solid red"} : null}
			onChange={ingredients => onChange(Object.assign({}, value, {ingredients}))}
		/>
		{
			onChange || value.hasOwnProperty("method") ? <TextAreaScannable
				disabled={!onChange}
				placeholder="Method"
				value={value.method || ""}
				style={errors && errors.includes("method") ? { border: "1px solid red"} : null}
				onChange={method => onChange(Object.assign({}, value, {method}))}
			/> : null
		}
	</RecipeFormContainer>

}

export default RecipeForm;