import usePortals from "./../hooks/usePortals";


const Modal = ({children, onClose, style}) => {
	const portals = usePortals();

	return portals?.inject(<div style={{
		position: 'absolute',
		top: "0px",
		left: "0px",
		right: "0px",
		bottom: "0px",
		backgroundColor: "rgba(0, 0, 0, 0.5)",
		pointerEvents: "auto",
	}}>
		<div
			style={{
				position: "absolute",
				top: "0px",
				left: "0px",
				right: "0px",
				bottom: "0px",
			}}
			onClick={onClose}
		/>
		<div style={Object.assign({
			position: "absolute",
			top: "50%",
			left: "50%",
			width: "400px",
			maxWidth: "90%",
			transform: "translate(-50%, -50%)",
			padding: "24px",
			borderRadius: "3px",
			height: "300px",
			maxHeight: "90%",
			backgroundColor: "#fff",
		}, style)}>
			{ children }
		</div>
	</div>)
}

export default Modal;