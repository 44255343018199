
const errors = [
	{
		find: "auth/wrong-password",
		contexts: {
			default: "Incorrect password or email."
		}
	},
	{
		find: "auth/user-not-found",
		contexts: {
			default: "No account exists for this email. Try singing up."
		}
	},
	{
		find: "auth/email-already-in-use",
		contexts: {
			default: "This account is already signed up. Try signing in."
		}
	},
	{
		find: "Missing or insufficient permissions",
		contexts: {
			default: "What you're looking for either doesn't exist, may no longer exist, or you aren't allowed to see it.",
		}
	}
]

export const decodeError = (error, context) => {
	const err = errors.find(e => error.message.includes(e.find));
	if(err){
		return err.contexts[context] || err.contexts.default;
	} else {
		console.log(`Unhandled error message: "${error.message}"`);
		return "An unknown error occurred."
	}
}