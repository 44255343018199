import { Button } from "./";
import useSharedState from "./../hooks/useSharedState";
import { useEffect, useState } from "react";
import { pl } from "./../utils/grammar"

const WakeLockButton = () => {

	const [ on, setOn ] = useSharedState("wakeLock");

	const [ supported ] = useState(!!navigator.wakeLock);
	const [ error, setError ] = useState(null)
	const [ timedOut, setTimedOut ] = useState(false)
	const [ minutesRemaining, setMinutesRemaining ] = useState(0);

	useEffect(() => {
		if(!on){
			return;
		}
		if(minutesRemaining === 0){
			setOn(false)
		} else {
			const timeout = setTimeout(() => {
				setMinutesRemaining(minutesRemaining - 1)
				setTimedOut(true);
			}, 1000 * 60)
			return () => clearTimeout(timeout);
		}

	}, [ minutesRemaining, on, setOn ])

	useEffect(() => {
		if(!supported){
			return;
		}

		if(on){
			let cancelled = false;
			let unsub = null;
			navigator.wakeLock.request('screen')
			.then(wakeLock => {
				if(cancelled){
					return;
				}
				unsub = () => {
					wakeLock.release();
				}
			})
			.catch(error => {
				if(cancelled){
					return;
				}
				setError(error)
				setOn(false)
				setMinutesRemaining(0);
			})
			return () => {
				cancelled = true;
				if(unsub){
					unsub();
				}
			}
		}

	}, [ on, supported, setOn ])

	if(!supported){
		return null
	}

	return <div style={{
		display: "inline-block",
	}}>
		<Button
			style={{
				margin: "0px 0px 3px 0px",
				backgroundColor: on ? "yellow" : null,
			}}
			label={ error ? "Screen Lock Unavailable :/" :  on ? "Screen Lock On" : "Turn on Screen Lock" }
			disabled={error}
			onClick={() => {
				setMinutesRemaining(15);
				setTimedOut(false)
				setOn(!on)
			}}
		/>
		{
			error ? <>
				<p className="small center" style={{ color: "orange" }}>
					Your device might be low on battery or something.
				</p>
			</> :
			on ? <>
				<p
					className="small center"
					style={{
						color: minutesRemaining <= 2 ? "red" : null
					}}
				>~ {minutesRemaining} minute{pl(minutesRemaining)} remaining ~</p>
			</> : <>
			{
				<p className="small center">{ timedOut ? "Timer expired. Press to reset." : "Force your device to stay awake." }</p>
			}
			</>
		}
	</div>

}

export default WakeLockButton;