
export const getRecipeValidationErrors = recipe => {
	const ret = [];
	if(!recipe){
		return [ "unknown" ];
	}
	if(recipe.name.length === 0){
		ret.push("name");
	}
	if(recipe.ingredients.length === 0){
		ret.push("ingredients");
	}
	return ret;
}