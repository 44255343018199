import { useState } from "react";
import useAuth from "./../hooks/useAuth";
import { Loading, Error, RecipeForm, Button } from "./../components";
import { getRecipeValidationErrors } from "./../config/validation";
import { collection, addDoc, getFirestore } from "firebase/firestore";
import { Link } from "react-router-dom";

const New = () => {

	const { user } = useAuth();
	const [ recipe, setRecipe ] = useState({
		name: "New recipe",
		ingredients: "",
		method: ""
	});
	const [ recipeErrors, setRecipeErrors ] = useState([]);
	const [ loading, setLoading ] = useState(false);
	const [ error, setError ] = useState(null);
	const [ successId, setSuccessId ] = useState(null);

	const handleSubmit = async () => {
		const errors = getRecipeValidationErrors(recipe);

		setRecipeErrors(errors);

		if(errors.length === 0){
			setSuccessId(null);
			setError(null);
			setLoading(true);
			try {
				recipe.owner = user.uid;
				const newDoc = await addDoc(collection(getFirestore(), "recipes"), recipe);
				setSuccessId(newDoc.id);
			} catch (error) {
				setError(error);
			} finally {
				setLoading(false)
			}
		}
	}

	return <>
		<h2>Create Recipe</h2>
		<RecipeForm value={recipe} errors={recipeErrors} onChange={setRecipe} />
		<Button label="Save" onClick={handleSubmit} />
		<Error error={error} />
		<Loading label="Saving recipe" loading={loading} />
		{
			successId ? <>
				<p>Recipe saved successfully!</p>
				<Link to={`/recipe/${successId}`}>View Recipe</Link>
			</> : null
		}
	</>

}

export default New;