import { initializeApp } from 'firebase/app';
import "firebase/auth";

const firebaseConfig = {
	apiKey: "AIzaSyBiClz8SUbqZN7mb7IZYzKDB_qDa3pJado",
	authDomain: "plain-text-food-website.firebaseapp.com",
	projectId: "plain-text-food-website",
	storageBucket: "plain-text-food-website.appspot.com",
	messagingSenderId: "820296322304",
	appId: "1:820296322304:web:4e9497532c3371405537e5",
	measurementId: "G-HCPM9ZEHGQ"
};
initializeApp(firebaseConfig);