import { NavLink, useHistory } from "react-router-dom";
import useAuth, { signOut } from "./../hooks/useAuth";
import styled from "styled-components";

const LinkContainer = styled.div`
	display: flex;

	p {
		margin: 12px 24px 12px 0px;
	}

	.active {
		p {
			color: blue;	
		}
	}

	.nav-wrapper {
		height: fit-content;
	}

	.auth-container {
		text-align: right;

		p {
			margin: 12px 0px 0px 0px;
		}

		.links-container {
			display: flex;

			p {
				margin: 12px 6px;
			}
		}
	}
`;

const EmailLabel = styled.p`
	font-style: italic;
	margin-right: 12px;
`

const Link = ({to, children, onClick}) => <NavLink to={to} exact className="nav-wrapper" activeClassName={!onClick ? "active" : null} onClick={onClick}>
	<p className="auth-link">{children}</p>
</NavLink>

const Header = () => {

	const { anonymous, signingIn, user } = useAuth();

	const history = useHistory();

	return <LinkContainer>
		<Link to="/">Home</Link>
		<Link to="/new">New</Link>
		<Link to="/about">About</Link>
		<div style={{
			flex: 1
		}} />
		<div className="auth-container">
			<EmailLabel>{
				signingIn ? "..." : anonymous ? "Guest account" : user?.email
			}</EmailLabel>
			{
				anonymous ? <div className="links-container">
					<Link to="/sign-in">Sign In</Link><p> / </p><Link to="/sign-up">Sign Up</Link>
				</div> : 
				signingIn ? null :
				<Link to="/" onClick={async () => {
					await signOut()
					history.push("/sign-in");
				}}>Sign Out</Link>
			}
		</div>
	</LinkContainer>
}

export default Header;