import { useState } from "react";
import { Loading, Error, Input, Button } from "./../components";
import { useHistory, useParams } from "react-router-dom";
import { signIn, signUp } from "./../hooks/useAuth";

const SignIn = () => {

	const { method } = useParams();

	const [ email, setEmail ] = useState("");
	const [ password, setPassword ] = useState("");
	const [ signInError, setSignInError ] = useState(null);
	const [ signUpError, setSignUpError ] = useState(null);
	const [ signingIn, setSigningIn ] = useState(false);
	const [ signingUp, setSigningUp ] = useState(false);

	const history = useHistory();

	const handleSignIn = async () => {
		setSignInError(null)
		setSigningIn(true)
		try {
			await signIn(email, password);
			history.push("/");
		} catch (error) {
			setSignInError(error)
			setSigningIn(false);
		}
	}

	const handleSignUp = async () => {
		setSignUpError(null)
		setSigningUp(true)
		try {
			await signUp(email, password);
			history.push("/");
		} catch (error) {
			setSignUpError(error)
			setSigningUp(false);
		}
	}

	return <>
		<div style={{
			display: "flex",
		}}>	
			<h2
				style={{
					display: "inline-flex",
					marginRight: "12px",
					textDecoration: method === "in" ? 'underline' : null,
				}}
				onClick={() => history.push("/sign-in")}
			>Sign in</h2>
			<h2 style={{
				marginRight: "12px",
				display: "inline-flex"
			}}>/</h2>
			<h2
				style={{
					marginRight: "12px",
					display: "inline-flex",
					textDecoration: method === "up" ? 'underline' : null,
				}}
				onClick={() => history.push("/sign-up")}
			>Sign up</h2>
		</div>
		{
			method === "up" ? <div style={{
				maxWidth: "500px",
				border: "1px solid gray",
				padding: "12px",
			}}>
				<h4 style={{marginTop: "6px"}}>What's the deal?</h4>
				<p>Everything's free. We won't send you emails. We don't sell your data or anything. It's a super cheap site to keep up so aint no thang.</p>
				<p>You're welcome to use the site without signing up, but you'll lose your stuff if you switch devices or clear your browser cache.</p>
			</div> : null
		}
		<h4>Email address</h4>
		<Input
			value={email}
			onChange={setEmail}
			type="email"
		/>
		<h4>Password</h4>
		<Input
			value={password}
			onChange={setPassword}
			type="password"
			onSubmit={method === "in" ? handleSignIn : handleSignUp}
		/>
		<br />
		{
			method === "up"  ? <Error error={signUpError} /> : null
		}
		{
			method === "in"  ? <Error error={signInError} /> : null
		}
		{
			method === "in" ? <>
			{
				signingIn ? <Loading label="Signing in" /> : <Button label="Sign In" onClick={handleSignIn} />
			}
			</> : <>
				{

					signingUp ? <Loading label="Signing in" /> : <Button label="Sign Up" onClick={handleSignUp} />
				}
			</>
		}
	</>

}

export default SignIn;