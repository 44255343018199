import styled, { css } from "styled-components";

const StyledButton = styled.button`
	padding: 12px;
	margin: 0px 12px 12px 0px;
	cursor: pointer;
	height: fit-content;

	${ props => props.disabled && css`
			pointer-events: none;
			opacity: 0.8;
		` }
`

const Button = ({label, onClick, style, disabled}) => {

	return <StyledButton onClick={onClick} style={style} disabled={disabled}>{label}</StyledButton>
}

export default Button;